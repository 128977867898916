import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './SubscriptionPackage.module.css';
import danaLogo from '../../assets/dana-logo.svg';
import logo from '../../assets/sushiroll_logo.svg';
import { PAYMENT_GATEWAY_URL } from '../../config/URL';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';

function SubscriptionPackage({
  id,
  title,
  days,
  duration,
  durationType,
  price,
  subscriptions,
  gateway,
  packageCode,
  paymentGateway,
  ...otherProps
}) {
  const user = useSelector((state) => state.auth.user);

  const planPrice = useMemo(() => {
    const formatPrice = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `Rp ${formatPrice}`;
  }, [price]);

  const planDuration = useMemo(() => {
    if (paymentGateway) {
      if (+days > 0) {
        return `Valid for ${days} day${+days > 1 ? 's' : ''}`;
      }
    } else if (+duration > 0) {
      return `Valid for ${duration} ${durationType}${+duration > 1 ? 's' : ''}`;
    }
    return null;
  }, [days, duration, durationType, paymentGateway]);

  const renderPayments = (gateways) => {
    if (gateways !== 'telco') return '';

    const telcos = {
      'telco-isat': 'Indosat',
      'telco-hutch': '3',
      'telco-smart': 'Smartfren',
      'telco-tsel': 'Telkomsel',
      'telco-axis': 'Axis',
      'telco-xl': 'XL'
    };

    return Object.entries(otherProps.payments)
      .reduce((gws, [key, value]) => {
        const name = telcos[key];
        if (name && value.description)
          gws.push({ name, description: value.description });

        return gws;
      }, [])
      .map((payment) => (
        <li key={payment.name}>
          <strong>{payment.name}</strong>{' '}
          <span className={styles['plan-description']}>
            {payment.description}
          </span>
        </li>
      ));
  };

  const onPurchase = async () => {
    // do purchase logic
    const paramsEvent = {
      packageID: id,
      packageCode,
      packageTitle: title,
      packagePrice: price,
      packageDuration: `${days} day${+days > 1 ? 's' : ''}`
    };
    analytic(analyticTypes.event, analyticEvents.SUBSCRIPTION.SELECT_PACKAGE, {
      params: paramsEvent,
      user
    });

    window.location.href = `${PAYMENT_GATEWAY_URL}/?member=${user.account.id}&plan=${id}`;
  };

  return (
    <div className={styles['plan-wrapper']}>
      <p className={styles['plan-name']} title={title}>
        {title}
      </p>
      <div className={styles['plan-info']}>
        {paymentGateway && (
          <img
            className={styles['payment-logo']}
            alt='payment-logo'
            src={logo}
          />
        )}
        {gateway === 'dana' ? (
          <img
            className={styles['payment-logo']}
            alt='dana-logo'
            src={danaLogo}
          />
        ) : (
          ''
        )}
        {gateway !== 'telco' ? (
          <p className={styles['plan-price']}>{planPrice}</p>
        ) : (
          ''
        )}
        <p className={styles['plan-duration']}>{planDuration}</p>
        <ul>{renderPayments(gateway)}</ul>
        {gateway !== 'telco' ? (
          <button
            type='button'
            onClick={onPurchase}
            className={styles['btn-purchase']}
          >
            Purchase
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default SubscriptionPackage;
